







































import { Component, Vue } from "vue-property-decorator";
import { confirmDialogController } from "./confirm-dialog-controller";
@Component({})
export default class ConfirmDialog extends Vue {
  controller = confirmDialogController;
}
